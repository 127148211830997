import { Show, createSignal } from "solid-js";
import { useWire } from "~/wire";
import { FeedbackForm } from "~/components/feedback/Feedback";

export const Bug = () => {
  const wire = useWire();
  const [diagnosticsLabel, setDiagnosticsLabel] =
    createSignal("Share Feedback");

  const onClick = async () => {
    wire.services.feedback.openFeedbackPanel();
  };

  return (
    <>
      <Show when={wire.flags.isDebug()}>
        <div class="fixed bottom-0 right-0 md:bottom-3 md:right-5 text-xs font-mono z-max">
          <button
            type="button"
            onClick={() => onClick()}
            class="w-full px-4 font-bold bg-transparent md:bg-pink-700 text-slate-500 md:text-white"
          >
            {diagnosticsLabel()}
          </button>
        </div>
      </Show>
      <Show when={wire.services.feedback.isFeedbackPanelOpen()}>
        <FeedbackForm additionalMetadata={{}} />
      </Show>
    </>
  );
};
