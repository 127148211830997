export interface StorageService {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

class BrowserStorageService implements StorageService {
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

class NoOpStorageService implements StorageService {
  getItem(key: string): string | null {
    return null;
  }

  setItem(key: string, value: string): void {}

  removeItem(key: string): void {}
}

export function createStorageService(isServer: boolean): StorageService {
  if (!isServer) {
    return new BrowserStorageService();
  }
  return new NoOpStorageService();
}
